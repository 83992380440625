import SVGInstagram from '../assets/svg/socials/SVGInstagram';
import SVGTelegram from '../assets/svg/socials/SVGTelegram';
import SVGYouTube from '../assets/svg/socials/SVGYouTube';
import SVGVk from '../assets/svg/socials/SVGVk';
import { getId } from './helpers';
import SVGDocument from '../assets/svg/SVGDocument';
import SVGEnvelope from '../assets/svg/SVGEnvelope';
import SVGTasks from '../assets/svg/SVGTasks';
import SVGProducts from '../assets/svg/SVGProducts';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = `${BASE_URL}/api`;

export const DASHBOARD_LOGIN_URL = `${window.location.protocol}//invest.${window.location.host}/sign`;
export const DASHBOARD_SIGNUP_URL = `${window.location.protocol}//invest.${window.location.host}/sign/up`;

export const LOCALES = [
  { code: 'ru', name: 'Русский', key: getId() },
  { code: 'en', name: 'English', key: getId() },
  { code: 'tu', name: 'Türkçe', key: getId() },
];

export const YOUTUBE_VIDEO = 'https://www.youtube.com/embed/wvY27ft47RA';
export const SOCIALS_LIST = [
  {
    key: getId(), icon: <SVGTelegram />, title: 'Telegram', link: 'https://t.me/tethex_ru',
  },
  {
    key: getId(), icon: <SVGInstagram />, title: 'Instagram', link: 'https://www.instagram.com/tethex_finance',
  },
  {
    key: getId(), icon: <SVGYouTube />, title: 'YouTube', link: 'https://www.youtube.com/@Tethex',
  },
  {
    key: getId(), icon: <SVGVk />, title: 'VK', link: 'https://vk.com/tethex',
  },
];
export const DEFAULT_MENU = [
  {
    key: getId(), icon: <SVGTasks />, title: 'menu.tasks', link: '#tasks',
  },
  {
    key: getId(), icon: <SVGProducts />, title: 'menu.products', link: '#products',
  },
  {
    key: getId(), icon: <SVGDocument />, title: 'menu.documents', link: '#documents',
  },
  {
    key: getId(), icon: <SVGEnvelope />, title: 'menu.contact', link: '#contact',
  },
];

export const LOCALES_LOCAL_STORAGE_KEY = 'i18nextLng';
