import axios from 'axios';
import { API_URL } from '../features/constants';

export const api = axios.create({
  baseURL: API_URL,
});

export async function sendMail(sendData) {
  try {
    const { data } = await api.post('/appsettings/bids/create/', sendData);
    return data;
  } catch (e) {
    throw e;
  }
}
