import SVGLogo from '../../assets/svg/SVGLogo';
import { anchorLink } from '../../features/helpers';
import { DEFAULT_MENU, SOCIALS_LIST } from '../../features/constants';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';

export default function Footer() {
  const { t } = useCustomTranslation();
  return (
    <>
      <div className="footer">
        <div className="secondary-container">
          <div className="footer__row--flex">
            <div className="footer__side">
              <SVGLogo />
            </div>
            <div className="footer__menu-lg">
              <ul>
                {DEFAULT_MENU.map(({ key, link, title }) => (
                  <li key={key}>
                    <a onClick={() => anchorLink(link)}>
                      { t(title) }
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer__socials-lg">
              <ul>
                {SOCIALS_LIST.map(({
                  key, title, icon, link,
                }) => (
                  <li key={key}>
                    <a href={link} title={title} target="_blank" rel="noreferrer">
                      { icon }
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer-line">
        <span>
          © 2024 tethex.io. All rights reserved.
        </span>
        <div className="footer-line__links">
          <a href="#">
            Terms of Service
          </a>
          |
          <a href="#">
            Privacy Terms
          </a>
        </div>
      </div> */}
    </>
  );
}
