export default function SVGArrowDown({
  fill = null,
}) {
  return (
    <svg width="35" height="21" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0178 19.968C20.1944 19.7924 20.3435 19.598 20.4732 19.396L34.0099 5.84184C35.3298 4.51899 35.3305 2.37506 34.0092 1.05158C32.6887 -0.271284 30.5468 -0.271284 29.2249 1.05158L17.567 12.7252L5.84772 0.992614C4.52776 -0.330873 2.38525 -0.330873 1.0634 0.992613C0.403732 1.65436 0.0735892 2.52058 0.0735892 3.38681C0.0735891 4.25303 0.403736 5.12114 1.06466 5.781L14.6608 19.396C14.7905 19.598 14.939 19.7918 15.1163 19.968C15.7929 20.6454 16.6812 20.9704 17.567 20.9553C18.4535 20.971 19.3431 20.6454 20.0178 19.968Z" fill={fill || 'url(#paint0_linear_1148_64671)'} />
      <defs>
        <linearGradient id="paint0_linear_1148_64671" x1="17.5368" y1="20.9558" x2="17.5368" y2="-8.48639e-07" gradientUnits="userSpaceOnUse">
          <stop stopColor="#50D0CF" />
          <stop offset="1" stopColor="#20ADAC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
