/* eslint-disable no-unused-vars */
import { useField } from 'formik';
import { useState } from 'react';
import { cx, getId } from '../../../features/helpers';

export default function FormikTextarea({
  label = '', name = '', placeholder = '', id = '', type = 'text', className = '', limit = null,
}) {
  const [counter, setCounter] = useState(0);
  const [field, { touched, error, value }, { setValue, setTouched }] = useField(name);
  const [generatedId] = useState(id || getId());
  const isError = error && touched;
  const keyUpHandler = (e) => {
    setCounter(e.target.value.length);
  };
  return (
    <div className={cx('input--holder', className)}>
      <label htmlFor={generatedId}>{label}</label>
      {limit && (
        <span className="input--counter">
          {counter}
          /
          {limit}
        </span>
      )}
      <textarea
        type={type}
        id={generatedId}
        style={{ resize: 'none' }}
        placeholder={placeholder}
        className={cx('default-input', (error && touched) && '--error')}
        onKeyUp={keyUpHandler}
        {...field}
      />
      {isError && (
        <span className="input--error">
          {error}
        </span>
      )}
    </div>
  );
}
