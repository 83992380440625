/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import * as yup from 'yup';
import { ValidationError } from 'yup';

yup.addMethod(yup.string, 'notCyrillic', function (message) {
  return this.test('notCyrillic', (string, obj, a) => {
    if (typeof string !== 'string') return true;
    if (string.length === 0) return true;
    const regex = /[\u0400-\u04FF]/;

    if (regex.test(string)) {
      return new ValidationError(
        `${message}`,
        string,
        obj.path,
      );
    }
    return true;
  });
});
