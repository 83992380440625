import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { useLayoutEffect } from 'react';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin);

export default function ScrollFunctions({ children }) {
  useLayoutEffect(() => {
    const scroller = ScrollSmoother.create({
      effects: true,
    });

    scroller.effects('.product-block__image', { speed: 1.5 });
    scroller.effects('.product-block__info', { speed: 1.3 });

    scroller.effects('.main__card.--white', { speed: 2.2, lag: 0.2 });
    scroller.effects('.main__card.--emerald', { speed: 1.7, lag: 0.2 });
    scroller.effects('.main__card.--gold', { speed: 1.3, lag: 0.2 });
    scroller.effects('.main__card.--black', { speed: 2.5, lag: 0.2 });

    const mm = gsap.matchMedia();

    mm.add('(min-width: 768px)', () => {
      // const tl = gsap.timeline();
      // const listWrapper = document.querySelector('.section.banks');
      // const list = document.querySelector('.banks__row');
      // tl.to(list, {
      //   x: `-${list.offsetWidth - window.outerWidth + 220}`,
      //   scrollTrigger: {
      //     trigger: listWrapper,
      //     start: 'top top',
      //     end: `+=${list.offsetWidth - window.outerWidth + 220}`,
      //     pin: true,
      //     scrub: 1,
      //   },
      // });

      scroller.effects('.main__title', { speed: 0.9 });
      scroller.effects('.main__desc', { speed: 0.9 });
      scroller.effects('.main__button', { speed: 0.9 });
    });
  }, []);
  return (
    <div id="smooth-content">
      {children}
    </div>
  );
}
