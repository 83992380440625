import Slider from 'react-slick';
// import SVGPhoneSm from '../../assets/svg/SVGPhoneSm';
import SVGPlacemarkSm from '../../assets/svg/SVGPlacemarkSm';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SafeHTML from '../../components/SafeHTML';

export default function Geography() {
  const { t } = useCustomTranslation();
  const sliderSettings = {
    responsive: [
      {
        breakpoint: 99999,
        settings: 'unslick',
      },
      {
        breakpoint: 960,
        settings: {
          dots: false,
          arrows: false,
          centerMode: true,
          variableWidth: true,
          infinite: false,
          centerPadding: 0,
        },
      },
    ],
  };

  return (
    <div className="secondary-container geography-container">
      <div className="default-title --lg">
        <SafeHTML html={t('geography.title')} />
      </div>
      <Slider {...sliderSettings} className="geography-grid">
        <div className="geography-block lag-01">
          <div className="geography-block__icon">
            <div className="geography-block__icon--inset">
              <img src={require('../../assets/img/flags/uae.png')} alt="" />
            </div>
          </div>
          <div className="geography-block__sub-title">
            {t('geography.dubai.type')}
          </div>
          <div className="geography-block__title">
            {t('geography.dubai.city')}
          </div>
          <div className="geography-block__country">
            {t('geography.dubai.country')}
          </div>
          <div className="geography-block__list">
            <div className="geography-block__info">
              <span>
                <SVGPlacemarkSm />
              </span>
              <div>
                <SafeHTML html={t('geography.dubai.address')} />
              </div>
            </div>
          </div>
        </div>
        <div className="geography-block lag-03">
          <div className="geography-block__icon">
            <div className="geography-block__icon--inset">
              <img src={require('../../assets/img/flags/tk.png')} alt="" />
            </div>
          </div>
          <div className="geography-block__soon">
            <SafeHTML html={t('geography.china.soon')} />
          </div>
        </div>
      </Slider>
    </div>
  );
}
