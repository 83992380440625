import SVGShield from '../../assets/svg/SVGShield';
import SafeHTML from '../../components/SafeHTML';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';

export default function Banks() {
  const { t } = useCustomTranslation();
  return (
    <div className="section banks --contrast --lg-p">
      <div className="default-title --contrast">
        <div className="default-title__shield">
          <SVGShield />
        </div>
        <SafeHTML html={t('banks.title')} />
      </div>
      <div className="banks__holder">
        <div className="banks__row">
          <div className="bank">
            {t('banks.block1')}
          </div>
          <div className="bank">
            {t('banks.block2')}
          </div>
        </div>
      </div>
      <div className="banks__bottom">
        <SafeHTML html={t('banks.smartphone')} />
      </div>
    </div>
  );
}
