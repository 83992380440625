/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
import cxx from 'classnames/bind';
import gsap from 'gsap';
import { toast } from 'react-toastify';

export const getId = () => Date.now().toString(36) + Math.random().toString(36).substring(2);
export function FUNCTION_RETURN_NULL() {
  return null;
}

export function cx(...rest) {
  return cxx(...rest);
}

export const anchorLink = (target) => {
  gsap.to(window, {
    scrollTo: document.querySelector(target),
    ease: 'power8',
  });
};

export function createErrorMessage(element, ignoreKeys = false) {
  const obj = element?.response?.data || element?.message || element;
  const arr = [];
  if (typeof obj === 'string') return obj;
  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const key in obj) {
    const element = obj[key];
    const keyReplace = key === 'detail' ? '' : key;
    if (Array.isArray(element)) {
      const filteredArray = element.filter((message) => message !== 'error');
      if (filteredArray.length !== 0) {
        const string = filteredArray.join('<br/>');
        // const keyString = ignoreKeys ? '' : (keyReplace ? `${capitalCase(noCase(keyReplace))}:` : '');
        const resultString = `${string}`;
        arr.push(resultString);
      }
    }
    if (typeof element === 'string') {
      // const keyString = ignoreKeys ? '' : (keyReplace ? `${capitalCase(noCase(keyReplace))}:` : '');
      const resultString = `${element}`;
      arr.push(resultString);
    }
    if (Object.isObject(element)) {
      const res = createErrorMessage(element);
      arr.push(...res);
    }
  }
  return arr.join('<br/>');
}

export function errorToast({ text, ignoreKeys }) {
  const err = createErrorMessage(text, ignoreKeys);
  toast.error(<div dangerouslySetInnerHTML={{ __html: err }} />);
}

export function successToast({ text, options = {} } = {}) {
  toast.success(<div dangerouslySetInnerHTML={{ __html: text }} />, { autoClose: 1000, ...options });
}
