import Slider from 'react-slick';
import SVGPlacemark from '../../assets/svg/SVGPlacemark';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SafeHTML from '../../components/SafeHTML';

export default function Events() {
  const { t } = useCustomTranslation();
  const slick = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="section events --contrast">
      <div className="secondary-container">
        <div className="default-title --contrast --lg">
          <SafeHTML html={t('events.title')} />
        </div>
        <Slider {...slick} className="events-list">
          {/* <div className="event">
            <div className="event__inset">
              <div className="event__date">
                <SafeHTML html={t('events.kazan.date')} />
              </div>
              <div className="event__title">
                <SafeHTML html={t('events.kazan.title')} />
              </div>
              <div className="event__image">
                <img src={require('../../assets/img/events/kazan.jpg')} alt="" />
              </div>
              <div className="event__text">
                <SafeHTML html={t('events.kazan.desc')} />
              </div>
              <div className="event__place">
                <div className="event__place--icon">
                  <SVGPlacemark />
                </div>
                <div className="event__place--address">
                  <SafeHTML html={t('events.kazan.place')} />
                </div>
              </div>
              <div className="event__button">
                <button className="default-button --lg w-100">
                  Подробнее →
                </button>
              </div>
            </div>
          </div> */}
          <div className="event">
            <div className="event__inset">
              <div className="event__date">
                <SafeHTML html={t('events.dubai.date')} />
              </div>
              <div className="event__title">
                <SafeHTML html={t('events.dubai.title')} />
              </div>
              <div className="event__image">
                <img src={require('../../assets/img/events/dubai.jpg')} alt="" />
              </div>
              <div className="event__text">
                <SafeHTML html={t('events.dubai.desc')} />
              </div>
              <div className="event__place">
                <div className="event__place--icon">
                  <SVGPlacemark />
                </div>
                <div className="event__place--address">
                  <SafeHTML html={t('events.dubai.place')} />
                </div>
              </div>
              {/* <div className="event__button">
                <button className="default-button --lg w-100">
                  Подробнее →
                </button>
              </div> */}
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
