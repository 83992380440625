import Slider from 'react-slick';
import SVGAnonymous from '../../assets/svg/SVGAnonymous';
import SVGArrowDown from '../../assets/svg/SVGArrowDown';
import SVGDeposite from '../../assets/svg/SVGDeposite';
import SVGDocument from '../../assets/svg/SVGDocument';
import SVGGlobe from '../../assets/svg/SVGGlobe';
import SVGGraduate from '../../assets/svg/SVGGraduate';
import SVGIncome from '../../assets/svg/SVGIncome';
import SVGLock from '../../assets/svg/SVGLock';
import SVGPhone from '../../assets/svg/SVGPhone';
import SVGShoppingBag from '../../assets/svg/SVGShoppingBag';
import SVGSuitCase from '../../assets/svg/SVGSuitCase';
import SVGTethex from '../../assets/svg/SVGTethex';
import SVGUsdt from '../../assets/svg/SVGUsdt';
import SVGWallet from '../../assets/svg/SVGWallet';
import SVGAcademy from '../../assets/svg/platforms/SVGAcademy';
import SVGAcademyLg from '../../assets/svg/platforms/SVGAcademyLg';
import SVGAgency from '../../assets/svg/platforms/SVGAgency';
import SVGCards from '../../assets/svg/platforms/SVGCards';
import SVGCardsLg from '../../assets/svg/platforms/SVGCardsLg';
import SVGGlobalFinance from '../../assets/svg/platforms/SVGGlobalFinance';
import SVGGlobalFinanceLg from '../../assets/svg/platforms/SVGGlobalFinanceLg';
import SVGInvest from '../../assets/svg/platforms/SVGInvest';
import SVGInvestLg from '../../assets/svg/platforms/SVGInvestLg';
import Rbr from '../../components/Rbr';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SafeHTML from '../../components/SafeHTML';
import { cx } from '../../features/helpers';

export default function Products() {
  const { t, lng } = useCustomTranslation();
  const sliderSettings = {
    className: 'products-container__row',
    responsive: [
      {
        breakpoint: 99999,
        settings: 'unslick',
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
          centerMode: true,
          variableWidth: true,
          infinite: false,
          centerPadding: 0,
        },
      },
    ],
  };
  return (
    <div className={cx('section products', `--${lng}`)} id="products">
      <div className="main-container prod-container">
        <div className="default-title --lg">
          <SafeHTML html={t('products.title')} />
        </div>
        <div className="products-container">
          <Slider {...sliderSettings}>
            <div className="products-container__block">
              <div className="products-container__block--logo">
                <SVGCards />
              </div>
              <SVGTethex />
              <div className="products-container__block--name">
                {t('products.cardsTitle')}
              </div>
              <div className="products-container__block--description">
                <SafeHTML html={t('products.cardsDesc')} />
              </div>
            </div>
            <div className="products-container__block">
              <div className="products-container__block--logo">
                <SVGGlobalFinance />
              </div>
              <SVGTethex />
              <div className="products-container__block--name">
                {t('products.globalFinanceTitle')}
              </div>
              <div className="products-container__block--description">
                <SafeHTML html={t('products.globalFinanceDesc')} />
              </div>
            </div>
            <div className="products-container__block">
              <div className="products-container__block--logo">
                <SVGInvest />
              </div>
              <SVGTethex />
              <div className="products-container__block--name">
                {t('products.investTitle')}
              </div>
              <div className="products-container__block--description">
                <SafeHTML html={t('products.investDesc')} />
              </div>
            </div>
            <div className="products-container__block">
              <div className="products-container__block--logo">
                <SVGAcademy />
              </div>
              <SVGTethex />
              <div className="products-container__block--name">
                {t('products.academyTitle')}
              </div>
              <div className="products-container__block--description">
                <SafeHTML html={t('products.academyDesc')} />
              </div>
            </div>
            <div className="products-container__block">
              <div className="products-container__block--logo">
                <SVGAgency />
              </div>
              <SVGTethex />
              <div className="products-container__block--name">
                {t('products.partnerTitle')}
              </div>
              <div className="products-container__block--description">
                <SafeHTML html={t('products.partnerDesc')} />
              </div>
            </div>
          </Slider>
        </div>
        <div className="arrow-down">
          <SVGArrowDown />
        </div>
      </div>
      <div className="product-blocks">
        <div className="product-block section secondary-container --contrast">
          <div className="main-container">
            <div className="product-block__grid">
              <div className="product-block__image">
                <img src={require('../../assets/img/products/1.png')} alt="" />
                {/* <button className="default-button --lg">
                  Подробнее →
                </button> */}
                <div className="product-block__number">
                  1
                </div>
              </div>
              <div className="product-block__info">
                <div className="product-block__icon">
                  <SVGGlobalFinanceLg />
                </div>
                <div className="default-title --md --left">
                  <SafeHTML html={t('productBlocks.crossBorderTitle')} />
                </div>
                <div className="product-block__text">
                  <p>
                    <SafeHTML html={t('productBlocks.crossBorderSlogan')} />
                  </p>
                  <p>
                    <SafeHTML html={t('productBlocks.crossBorderDesc')} />
                  </p>
                </div>
                <div className="product-block__advantages">
                  <div className="product-block__advantage">
                    <div className="product-block__advantage--icon">
                      <SVGLock />
                    </div>
                    <div className="product-block__advantage--title">
                      <SafeHTML html={t('productBlocks.crossBorderAdvantage1')} />
                    </div>
                  </div>
                  <div className="product-block__advantage">
                    <div className="product-block__advantage--icon">
                      <SVGGlobe />
                    </div>
                    <div className="product-block__advantage--title">
                      <SafeHTML html={t('productBlocks.crossBorderAdvantage2')} />
                    </div>
                  </div>
                  <div className="product-block__advantage --big">
                    <div className="product-block__advantage--icon">
                      <SVGAnonymous />
                    </div>
                    <div className="product-block__advantage--title">
                      <SafeHTML html={t('productBlocks.crossBorderAdvantage3')} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-block section secondary-container">
          <div className="main-container">
            <div className="product-block__grid">
              <div className="product-block__image">
                <img src={require('../../assets/img/products/2.png')} alt="" />
                {/* <button className="default-button --lg">
                  Подробнее →
                </button> */}
                <div className="product-block__number">
                  2
                </div>
              </div>
              <div className="product-block__info">
                <div className="product-block__icon">
                  <SVGCardsLg />
                </div>
                <div className="default-title --md --left">
                  <SafeHTML html={t('productBlocks.virtualCardsTitle')} />
                </div>
                <div className="product-block__text">
                  <p>
                    <SafeHTML html={t('productBlocks.virtualCardsSlogan')} />
                  </p>
                  <p>
                    <SafeHTML html={t('productBlocks.virtualCardsDesc')} />
                  </p>
                </div>
                <div className="product-block__advantages">
                  <div className="product-block__advantage">
                    <div className="product-block__advantage--icon">
                      <SVGUsdt />
                    </div>
                    <div className="product-block__advantage--title">
                      <SafeHTML html={t('productBlocks.virtualCardsAdvantage1')} />
                    </div>
                  </div>
                  <div className="product-block__advantage">
                    <div className="product-block__advantage--icon">
                      <SVGPhone />
                    </div>
                    <div className="product-block__advantage--title">
                      <SafeHTML html={t('productBlocks.virtualCardsAdvantage2')} />
                    </div>
                  </div>
                  <div className="product-block__advantage --big">
                    <div className="product-block__advantage--icon">
                      <SVGShoppingBag />
                    </div>
                    <div className="product-block__advantage--title">
                      <SafeHTML html={t('productBlocks.virtualCardsAdvantage3')} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="product-block section secondary-container --contrast">
          <div className="main-container">
            <div className="product-block__grid">
              <div className="product-block__image">
                <img src={require('../../assets/img/products/3.png')} alt="" />
                <button className="default-button --lg">
                  Подробнее →
                </button>
                <div className="product-block__number">
                  3
                </div>
              </div>
              <div className="product-block__info">
                <div className="product-block__icon">
                  <SVGPaymentLg />
                </div>
                <div className="default-title --md --left">
                  Платежная
                  <br />
                  <span>система</span>
                </div>
                <div className="product-block__text">
                  <p>
                    <b>Tethex Payment</b>
                    {' '}
                    - выгодно и просто.
                  </p>
                  <p>
                    Прием платежей и организация выплат для High Risk бизнеса. Децентрализованная P2P система процессинга платежей и банковский эквайринг
                  </p>
                </div>
                <div className="product-block__advantages">
                  <div className="product-block__advantage">
                    <div className="product-block__advantage--icon">
                      <SVGUsdt />
                    </div>
                    <div className="product-block__advantage--title">
                      <span>Получение</span>
                      <br />
                      в USDT
                    </div>
                  </div>
                  <div className="product-block__advantage">
                    <div className="product-block__advantage--icon">
                      <SVGBank />
                    </div>
                    <div className="product-block__advantage--title">
                      <span>Банки</span>
                      <br />
                      более 50 стран
                    </div>
                  </div>
                  <div className="product-block__advantage --big">
                    <div className="product-block__advantage--icon">
                      <SVGSwipe />
                    </div>
                    <div className="product-block__advantage--title">
                      <span>Удобство</span>
                      <br />
                      для клиента
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="product-block section secondary-container --contrast">
          <div className="main-container">
            <div className="product-block__grid">
              <div className="product-block__image">
                <img src={require('../../assets/img/products/4.png')} alt="" />
                {/* <button className="default-button --lg">
                  Подробнее →
                </button> */}
                <div className="product-block__number">
                  3
                </div>
              </div>
              <div className="product-block__info">
                <div className="product-block__icon">
                  <SVGAcademyLg />
                </div>
                <div className="default-title --md --left">
                  <SafeHTML html={t('productBlocks.academyTitle')} />
                </div>
                <div className="product-block__text">
                  <p>
                    <SafeHTML html={t('productBlocks.academySlogan')} />
                  </p>
                  <p>
                    <SafeHTML html={t('productBlocks.academyDescription')} />
                  </p>
                </div>
                <div className="product-block__advantages">
                  <div className="product-block__advantage">
                    <div className="product-block__advantage--icon">
                      <SVGSuitCase />
                    </div>
                    <div className="product-block__advantage--title">
                      <SafeHTML html={t('productBlocks.academyAdvantage1')} />
                    </div>
                  </div>
                  <div className="product-block__advantage">
                    <div className="product-block__advantage--icon">
                      <SVGGraduate />
                    </div>
                    <div className="product-block__advantage--title">
                      <SafeHTML html={t('productBlocks.academyAdvantage2')} />
                    </div>
                  </div>
                  <div className="product-block__advantage --big">
                    <div className="product-block__advantage--icon">
                      <SVGWallet />
                    </div>
                    <div className="product-block__advantage--title">
                      <SafeHTML html={t('productBlocks.academyAdvantage3')} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-block">
          <div className="secondary-container">
            <div className="main-container">
              <div className="product-block__grid">
                <div className="product-block__image">
                  <img src={require('../../assets/img/products/5.png')} alt="" />
                  {/* <button className="default-button --lg">
                    Подробнее →
                  </button> */}
                  <div className="product-block__number">
                    4
                  </div>
                </div>
                <div className="product-block__info">
                  <div className="product-block__icon">
                    <SVGInvestLg />
                  </div>
                  <div className="default-title --md --left">
                    <SafeHTML html={t('productBlocks.investTitle')} />
                  </div>
                  <div className="product-block__text">
                    <p>
                      <SafeHTML html={t('productBlocks.investSlogan')} />
                    </p>
                    <p>
                      <SafeHTML html={t('productBlocks.investDesc')} />
                    </p>
                  </div>
                  <div className="product-block__advantages">
                    <div className="product-block__advantage">
                      <div className="product-block__advantage--icon">
                        <SVGIncome />
                      </div>
                      <div className="product-block__advantage--title">
                        <SafeHTML html={t('productBlocks.investAdvantage1')} />
                      </div>
                    </div>
                    <div className="product-block__advantage">
                      <div className="product-block__advantage--icon">
                        <SVGDeposite />
                      </div>
                      <div className="product-block__advantage--title">
                        <SafeHTML html={t('productBlocks.investAdvantage2')} />
                      </div>
                    </div>
                    <div className="product-block__advantage --big">
                      <div className="product-block__advantage--icon">
                        <SVGDocument />
                      </div>
                      <div className="product-block__advantage--title">
                        <SafeHTML html={t('productBlocks.investAdvantage3')} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
