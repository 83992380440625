import Slider from 'react-slick';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SafeHTML from '../../components/SafeHTML';

export default function Tasks() {
  const { t } = useCustomTranslation();
  const sliderSettings = {
    className: 'tasks__grid',
    responsive: [
      {
        breakpoint: 99999,
        settings: 'unslick',
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
          centerMode: true,
          variableWidth: true,
          infinite: false,
          centerPadding: 0,
        },
      },
    ],
  };

  return (
    <div className="tasks section" id="tasks">
      <div className="main-container">
        <div className="default-title">
          <SafeHTML html={t('tasks.title')} />
        </div>
        <Slider {...sliderSettings}>
          <div className="tasks__item" id="task1">
            <div className="tasks__item--content">
              <div className="tasks__item--image">
                <img src={require('../../assets/img/tasks/1.png')} alt="" />
              </div>
              <div className="tasks__item--text">
                <SafeHTML html={t('tasks.task1')} />
              </div>
            </div>
          </div>
          <div className="tasks__item" id="task2">
            <div className="tasks__item--content">
              <div className="tasks__item--image">
                <img src={require('../../assets/img/tasks/2.png')} alt="" />
              </div>
              <div className="tasks__item--text">
                <SafeHTML html={t('tasks.task2')} />
              </div>
            </div>
          </div>
          <div className="tasks__item" id="task3">
            <div className="tasks__item--content">
              <div className="tasks__item--image">
                <img src={require('../../assets/img/tasks/3.png')} alt="" />
              </div>
              <div className="tasks__item--text">
                <SafeHTML html={t('tasks.task3')} />
              </div>
            </div>
          </div>
          <div className="tasks__item --span-2" id="task4">
            <div className="tasks__item--content">
              <div className="tasks__item--image">
                <img src={require('../../assets/img/tasks/5.png')} alt="" />
              </div>
              <div className="tasks__item--text">
                <SafeHTML html={t('tasks.task4')} />
              </div>
            </div>
          </div>
          <div className="tasks__item --span-2" id="task5">
            <div className="tasks__item--content">
              <div className="tasks__item--image">
                <img src={require('../../assets/img/tasks/6.png')} alt="" />
              </div>
              <div className="tasks__item--text">
                <SafeHTML html={t('tasks.task5')} />
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
