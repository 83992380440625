import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import SVGLogo from '../../assets/svg/SVGLogo';
import { anchorLink, cx } from '../../features/helpers';
import SVGMenu from '../../assets/svg/SVGMenu';
import SVGShield from '../../assets/svg/SVGShield';
import {
  DASHBOARD_LOGIN_URL,
  DASHBOARD_SIGNUP_URL,
  DEFAULT_MENU,
  SOCIALS_LIST,
} from '../../features/constants';
import SVGCloseMenuIcon from '../../assets/svg/SVGCloseMenuIcon';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import LanguageSelector from '../../components/LanguageSelector';

export default function Menu() {
  const { t } = useCustomTranslation();
  const [scrollTop, setScrollTop] = useState(0);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  useEffect(() => {
    const scrollHandler = () => {
      if (window.scrollY >= 200) {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
    };
    window.addEventListener('scroll', scrollHandler, false);
    return () => window.removeEventListener('scroll', scrollHandler, false);
  }, []);
  return createPortal(
    <div className={cx('menu', (scrollTop && '--fixed'))}>
      <button className="menu-toggle" onClick={() => setMobileMenuVisible(true)}>
        <SVGMenu />
      </button>
      <div className="main-container">
        <div className="menu__holder">
          <div className="menu__logo" onClick={() => anchorLink('#main')}>
            <SVGLogo />
          </div>
          <div className="menu__logo--mobile">
            <SVGShield />
          </div>
          <nav className="menu__nav">
            <ul>
              {DEFAULT_MENU.map(({ key, link, title }) => (
                <li key={key}>
                  <a onClick={() => anchorLink(link)}>
                    { t(title) }
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="menu__right">
            <a href={DASHBOARD_LOGIN_URL} className="menu-link">
              {t('menu.login')}
            </a>
            <a href={DASHBOARD_SIGNUP_URL} className="default-button">
              {t('menu.signup')}
            </a>
            <LanguageSelector />
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isMobileMenuVisible && (
          <motion.div
            initial={{
              x: '-100%',
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{
              x: '-100%',
              opacity: 0,
            }}
            transition={{ ease: 'easeInOut' }}
            className="mobile-menu"
          >
            <div className="mobile-menu__top">
              <button className="menu-toggle" onClick={() => setMobileMenuVisible(false)}>
                <SVGMenu />
              </button>
              <div className="mobile-menu__logo">
                <SVGLogo />
              </div>
              <button className="mobile-menu__close" onClick={() => setMobileMenuVisible(false)}>
                <SVGCloseMenuIcon />
              </button>
            </div>
            <div className="mobile-menu__body">
              <ul>
                {DEFAULT_MENU.map(({
                  key, link, title, icon,
                }) => (
                  <li key={key}>
                    <a href="#" onClick={() => { anchorLink(link); setMobileMenuVisible(false); }}>
                      <span>
                        <i>
                          { icon }
                        </i>
                      </span>
                      { t(title) }
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mobile-menu__footer">
              <ul>
                {SOCIALS_LIST.map(({
                  key, title, icon, link,
                }) => (
                  <li key={key}>
                    <a href={link} title={title} target="_blank" rel="noreferrer">
                      { icon }
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>,
    document.getElementById('root'),
  );
}
