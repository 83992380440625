export default function SVGPasswordShow() {
  return (
    <svg width="36" height="22" viewBox="0 0 36 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.5508 9.9822C35.5508 9.9822 25.8033 0.292969 18.0011 0.292969C10.1988 0.292969 0.451216 9.9822 0.451216 9.9822C-0.14777 10.544 -0.153035 11.4663 0.451216 12.0175C0.451216 12.0175 10.1988 21.7068 18.0011 21.7068C25.8033 21.7068 35.5508 12.0175 35.5508 12.0175C36.1445 11.4557 36.1551 10.5388 35.5508 9.9822ZM18.0753 18.8022C13.7872 18.8022 10.3048 15.325 10.3048 11.0317C10.3048 6.73839 13.7819 3.26122 18.0753 3.26122C22.3686 3.26122 25.8457 6.74359 25.8457 11.0317C25.8457 15.3198 22.3633 18.8022 18.0753 18.8022Z" fill="url(#paint0_linear_763_5905)" />
      <path d="M17.9855 6.00687C15.2611 6.00687 13.0508 8.22772 13.0508 10.9575C13.0508 13.6872 15.2611 15.9081 17.9855 15.9081C20.71 15.9081 22.9203 13.6872 22.9203 10.9575C22.9203 8.22779 20.71 6.00687 17.9855 6.00687ZM19.846 10.9734C19.0033 10.9734 18.3142 10.2843 18.3142 9.43096C18.3142 8.57762 19.0033 7.88856 19.846 7.88856C20.6888 7.88856 21.3778 8.57762 21.3778 9.43096C21.3778 10.2843 20.6994 10.9734 19.846 10.9734Z" fill="url(#paint1_linear_763_5905)" />
      <defs>
        <linearGradient id="paint0_linear_763_5905" x1="36" y1="10.9999" x2="-1.46532e-07" y2="10.9999" gradientUnits="userSpaceOnUse">
          <stop stopColor="#50D0CF" />
          <stop offset="1" stopColor="#20ADAC" />
        </linearGradient>
        <linearGradient id="paint1_linear_763_5905" x1="22.9203" y1="10.9575" x2="13.0508" y2="10.9575" gradientUnits="userSpaceOnUse">
          <stop stopColor="#50D0CF" />
          <stop offset="1" stopColor="#20ADAC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
