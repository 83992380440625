import { motion } from 'framer-motion';
import SVGLogo from '../../assets/svg/SVGLogo';
import { cx } from '../../features/helpers';
import SVGShield from '../../assets/svg/SVGShield';

export default function Card({
  className = '',
  type = 'Classic',
  cardNumber = '',
  cardHolder = 'Anonymous',
  expireDate = '**/**',
  cvvCode = '***',
}) {
  const cardSplit = cardNumber.split(' ');
  // const { scrollY } = useScroll();
  // const y = useTransform(scrollY, [window.outerHeight / 2, 100], [(0 - (window.outerHeight * 1)), 0]);
  return (
    <motion.div className={cx('main__card', className)}>
      <div className="main__card--logo">
        <SVGLogo />
        <span>
          { type }
        </span>
      </div>
      <div className="main__card--number">
        <span>{ cardSplit?.[0] || '1111' }</span>
        <span>* * * *</span>
        <span>* * * *</span>
        <span>{ cardSplit?.[0] || '1111' }</span>
      </div>
      <div className="main__card--info">
        <div className="main__card--block">
          <span>Card holder</span>
          <big>{ cardHolder }</big>
        </div>
        <div className="main__card--block">
          <span>Expire date</span>
          <big>{ expireDate }</big>
        </div>
        <div className="main__card--block">
          <span>CVV</span>
          <big>{ cvvCode }</big>
        </div>
      </div>
      <SVGShield />
    </motion.div>
  );
}
