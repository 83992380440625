import { ToastContainer } from 'react-toastify';
import Banks from './blocks/Banks';
import Events from './blocks/Events';
import Main from './blocks/Main';
import Menu from './blocks/Menu';
import Products from './blocks/Products';
import Tasks from './blocks/Tasks';
import Video from './blocks/Video';
import Geography from './blocks/Geography';
import Contact from './blocks/Contact';
import Footer from './blocks/Footer';
import Documents from './blocks/Documents';
import ScrollFunctions from './components/ScrollFunctions';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './scss/App.scss';

export default function App() {
  return (
    <>
      <ScrollFunctions>
        <Menu />
        <Main />
        <Video />
        <Tasks />
        <Banks />
        <Products />
        <Events />
        <Geography />
        <Contact />
        <Footer />
      </ScrollFunctions>
      <ToastContainer
        hideProgressBar
        position="top-right"
        draggable={false}
        icon={false}
        autoClose={3000}
      />
    </>
  );
}
