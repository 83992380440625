import Card from '../../components/Card';
import SVGLogo from '../../assets/svg/SVGLogo';
import { DASHBOARD_SIGNUP_URL } from '../../features/constants';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SafeHTML from '../../components/SafeHTML';

export default function Main() {
  const { t } = useCustomTranslation();
  return (
    <div className="main" id="main">
      <video src={require('../../assets/video/map.mp4')} muted autoPlay playsInline preload />
      <div className="main__title">
        <div className="main__logo-mobile">
          <SVGLogo />
        </div>
        <SafeHTML html={t('main.title')} />
      </div>
      <div className="main__desc">
        <SafeHTML html={t('main.desc')} />
      </div>
      <div className="main__button">
        <a href={DASHBOARD_SIGNUP_URL} className="default-button --lg">
          {t('main.connect')}
        </a>
      </div>
      <div className="main__cards">
        <Card
          cardHolder="Anonymous"
          cardNumber="4321 1111 1111 1234"
          className="--white"
          type=""
        />
        <Card
          cardHolder="Anonymous"
          cardNumber="2515 1111 1111 7534"
          className="--emerald"
          type="Classic"
        />
        <Card
          cardHolder="Anonymous"
          cardNumber="5462 1111 1111 7935"
          className="--gold"
          type="Gold"
        />
        <Card
          cardHolder="Anonymous"
          cardNumber="3468 1111 1111 3094"
          className="--black"
          type="Black"
        />
      </div>
    </div>
  );
}
