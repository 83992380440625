import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import SVGShieldPlay from '../../assets/svg/SVGShieldPlay';
import { FUNCTION_RETURN_NULL } from '../../features/helpers';
import SVGClose from '../../assets/svg/SVGClose';
import { YOUTUBE_VIDEO } from '../../features/constants';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';

export default function Video() {
  const { t } = useCustomTranslation();
  const container = useRef();
  const [isShowModal, setShowModal] = useState(false);
  const [modalDimensions, setModalDimensions] = useState({
    width: 0, height: 0, left: 0, top: 0,
  });
  const togglePlayer = (value) => {
    const containerWidth = container.current.offsetWidth;
    const containerHeight = container.current.offsetHeight;
    const containerLeftOffset = `${container.current.getBoundingClientRect().x}px`;
    const containerTopOffset = `${container.current.getBoundingClientRect().y}px`;
    setModalDimensions((state) => ({
      ...state,
      width: containerWidth,
      height: containerHeight,
      left: containerLeftOffset,
      top: containerTopOffset,
    }));
    setShowModal(value);
  };
  return (
    <div className="video">
      <video src={require('../../assets/video/video-bg.mp4')} muted playsInline autoPlay loop />
      <div className="main-container">
        <div className="video__container" ref={container}>
          <button onClick={() => togglePlayer(true)}>
            <SVGShieldPlay />
          </button>
          <div className="video__container--title">
            { t('video.title') }
          </div>
        </div>
      </div>
      {
        createPortal(
          <VideoModal
            show={isShowModal}
            onClose={() => togglePlayer(false)}
            width={modalDimensions.width}
            height={modalDimensions.height}
            left={modalDimensions.left}
            top={modalDimensions.top}
          />,
          document.body,
        )
      }
    </div>
  );
}

function VideoModal({
  show = false,
  onClose = FUNCTION_RETURN_NULL,
  width = 0,
  height = 0,
  left = 0,
  top = 0,
}) {
  const duration = 300;
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setIsVideoVisible(true);
      }, duration);
    } else {
      setIsVideoVisible(false);
    }
  }, [show]);
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="video-modal"
          onClick={onClose}
          initial={{
            top,
            left,
            height,
            width,
            opacity: 0,
            borderRadius: 40,
          }}
          animate={{
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            opacity: 1,
            borderRadius: 0,
          }}
          exit={{
            top,
            left,
            height,
            width,
            opacity: 0,
            borderRadius: 40,
          }}
          transition={{ ease: 'easeInOut', duration: (duration / 1000) }}
        >
          <div className="video-modal__close">
            <SVGClose />
          </div>
          {isVideoVisible && (
            <iframe src={`${YOUTUBE_VIDEO}?autoplay=1`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
