import * as yup from 'yup';

export const contactValidation = () => {
  const requiredMessage = 'Обязательное поле';
  const onlyLatinMessage = 'Только латиница';
  const emailMessage = 'Введите валидный E-Mail';
  return yup.object().shape({
    email: yup
      .string()
      .required(requiredMessage)
      .notCyrillic(onlyLatinMessage)
      .email(emailMessage)
      .matches(/^\S+@\S+\.\S+$/, emailMessage)
      .matches(/@(\w{2,})/, emailMessage),
  });
};
