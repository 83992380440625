export default function SVGWallet() {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2682 5.30759C14.2682 3.42541 15.7995 1.89413 17.6816 1.89413H18.863L18.5066 0.903543C18.3062 0.346466 17.7812 0 17.2217 0C17.0667 0 16.909 0.026625 16.7548 0.0827423L4.81773 4.42746C4.10807 4.6858 3.74283 5.4711 4.00267 6.18021L4.63341 7.90182H14.2682V5.30759Z" fill="#D0B47D" />
      <path d="M17.6828 3.94141C16.9287 3.94141 16.3174 4.55269 16.3174 5.30679V7.90102H28.2645V5.30679C28.2645 4.55269 27.6532 3.94141 26.8991 3.94141H17.6828Z" fill="#D0B47D" />
      <path d="M31.8817 27.5988H27.1029C24.2796 27.5988 21.9827 25.3019 21.9827 22.4786C21.9827 19.6553 24.2796 17.3584 27.1029 17.3584H31.8817V12.6819C31.8817 11.1738 30.6591 9.95117 29.151 9.95117H2.73077C1.22263 9.95117 0 11.1738 0 12.6819V32.2243C0 33.7325 1.22263 34.9551 2.73077 34.9551H29.151C30.6591 34.9551 31.8817 33.7325 31.8817 32.2243V27.5988Z" fill="#D0B47D" />
      <path d="M33.9303 19.4062H27.1034C25.4094 19.4062 24.0312 20.7844 24.0312 22.4784C24.0312 24.1723 25.4094 25.5505 27.1034 25.5505H33.9303C34.4949 25.5505 34.9543 25.0911 34.9543 24.5264V20.4303C34.9543 19.8656 34.4949 19.4062 33.9303 19.4062ZM27.1034 23.5024C26.5378 23.5024 26.0793 23.0439 26.0793 22.4784C26.0793 21.9128 26.5378 21.4543 27.1034 21.4543C27.6689 21.4543 28.1274 21.9128 28.1274 22.4784C28.1274 23.0439 27.6689 23.5024 27.1034 23.5024Z" fill="#D0B47D" />
    </svg>
  );
}
