// локализации будут тут, в формате js, чтобы линтер работал. Достали двойные кавычки
export default {
  menu: {
    login: 'Giriş Yap',
    signup: 'Kayıt Ol',
    tasks: 'Görevlerimiz',
    products: 'Ürünler',
    documents: 'Belgeler',
    contact: 'İletişim',
  },
  main: {
    title: 'Yeni Nesil<br /><span>Ödeme Çözümleri</span>',
    desc: 'Dünya genelinde dijital varlıkları satın almak, satmak, <br /> <span>dönüştürmek ve çoğaltmak için yardımcı oluyoruz.</span>',
    connect: 'Bağlan',
  },
  video: {
    title: 'Para Teslimatı. Hatta Çöle Bile',
  },
  tasks: {
    title: 'Tethex Şirketi Hangi Sorunları Çözüyor?',
    task1: 'Dijital Varlıkların Sınır Ötesi Transferleri',
    task2: 'Dijital Varlıklarla Mal ve Hizmet Ödemeleri',
    task3: 'Dijital Varlıkların Alımı ve Satımı<br /><br />',
    task4: 'Dijital Varlıkların Artırılması<br /><br />',
    task5: 'Dijital Varlıkların Dönüştürülmesi<br /><br />',
  },
  banks: {
    title: 'Tethex - Geleneksel Finansın Kolaylığını ve <br />Dijital Varlıkların Avantajlarını Birleştiren <br /> <span>Dünyanın İlk Şirketi</span>',
    block1: 'Merkezi Olmayan Finanslarla Tethex Sanal Kartlarını Doldurun',
    block2: 'Tethex Kartlarıyla Alışveriş Yapın, Hizmetler Satın Alın ve Ödemeleri Gerçekleştirin',
    smartphone: 'Ve Tüm Bunlar <br /> <span>Cebinize Geliyor!</span>',
  },
  products: {
    title: 'Tethex Platformu <br /> <span>Ürünleri</span>',
    cardsTitle: 'Kartlar',
    cardsDesc: 'Dünya çapında ödemeler için kullanılabilir sanal kartlar',
    globalFinanceTitle: 'Küresel Finans',
    globalFinanceDesc: 'Dijital varlıkların sınır ötesi transferleri',
    investTitle: 'Yatırım',
    investDesc: 'Sermayenin artırılması için yatırım programı',
    academyTitle: 'Akademi',
    academyDesc: 'Dijital varlıklar alanındaki yetkinlikleri artıran eğitim hizmeti',
    partnerTitle: 'Ortak',
    partnerDesc: 'Şirket ortakları için ödül sistemi',
  },
  productBlocks: {
    crossBorderTitle: 'Sınır Ötesi <br /> <span>Transferler</span>',
    crossBorderSlogan: '<b>Tethex Global</b> - Güvenli ve Anonim.',
    crossBorderDesc: 'Platform, merkezi olmayan finansların dünya çapında karlı dönüşümünü ve transferini sağlar',
    crossBorderAdvantage1: '<span>Yüksek</span> <br /> güvenilirlik',
    crossBorderAdvantage2: '<span>Sınırsız</span> <br /> ve kısıtlamasız',
    crossBorderAdvantage3: '<span>Tam</span> <br /> gizlilik',
    virtualCardsTitle: 'Sanal <br /> <span>Kartlar</span>',
    virtualCardsSlogan: '<b>Tethex Kartlar</b> - Kazançlı ve Rahat.',
    virtualCardsDesc: 'Kripto kartlar ve ödeme çözümleri sizin ve işiniz için. Dünya çapında mal ve hizmet ödemeleri için mükemmel.',
    virtualCardsAdvantage1: '<span>USDT</span>&nbsp; ile doldurma',
    virtualCardsAdvantage2: 'Apple Pay ve Google Pay &nbsp;<span>ile uyumlu</span>',
    virtualCardsAdvantage3: '<span>Cashback</span>&nbsp; ve bakiye <br /> üzerinden faiz',
    academyTitle: 'Akademi <br /> <span>Kripto Finans</span>',
    academySlogan: '<b>Tethex Akademisi</b> - Profesyoneller Yetiştiriyor.',
    academyDescription: 'En talep gören becerileri ve profesyonel çalışma için en iyi araçları aktarıyoruz',
    academyAdvantage1: '<span>Her ülkede</span> <br /> istihdam',
    academyAdvantage2: '<span>Uygulamalı</span> <br /> eğitmenler',
    academyAdvantage3: '<span>Yüksek</span> <br /> acente geliri',
    investTitle: 'Yüksek Getirili <br /> <span>Yatırımlar</span>',
    investSlogan: '<b>Tethex Yatırım</b> - Kararlı USDT Geliri.',
    investDesc: 'Başarılı işletmelerin ölçeklenmesine yönelik yatırımlar. Şirket faaliyetlerinden elde edilen kârın bir kısmını alarak istikrarlı gelir elde eder.',
    investAdvantage1: '<span>Günlük</span> <br /> ödemeler',
    investAdvantage2: '<span>Erişilebilir</span> <br /> yatırımlar',
    investAdvantage3: '<span>Resmi</span> <br /> sözleşme',
  },
  events: {
    title: 'Etkinliklerimiz',
    kazan: {
      date: '27-29 Ocak 2024',
      title: 'İlk Konferans <br /> <span>Kazan 2024</span>',
      desc: 'Finans lojistiğine adanmış bir konferans',
      place: '<span>NEO Kazan Palace</span>&nbsp; by TASIGO <div>Kazan, Kalinin Caddesi, No: 3B</div>',
    },
    dubai: {
      date: 'Yakında duyuru...',
      title: 'Finance Logistic <br /> <span>Dubai 2024</span>',
      desc: '<b>Tethex</b> - Finansal sistemde yeni bir aşama',
      place: '<span>Yakında duyuru</span>',
    },
  },
  geography: {
    title: 'Coğrafyamız <br /> <span></span>',
    dubai: {
      type: 'Merkez Ofis',
      city: 'Dubai',
      country: 'Birleşik Arap Emirlikleri',
      address: 'İş Körfezi Dubai',
    },
    russia: {
      type: 'Temsilcilik',
      city: 'Kazan',
      country: 'Rusya',
      address: 'Tethex Finans LLC, <br /> Kazan, Rusya <br /> Sary Sadykov Caddesi 30 <br /> Bahadir Alışveriş Merkezi, 3. Kat',
    },
    china: {
      soon: 'Yakında <br /> açılış',
    },
  },
  certificates: {
    title: 'Sertifikalarımız',
    certificate1: 'BAE\'de iş yapma <br /> lisansı',
    certificate2: 'Dubai\'de <br /> işletme kaydı',
    certificate3: 'Yönetim kalite <br /> standart sertifikası',
  },
  contacts: {
    title: 'Bizimle <br /> <span>İletişime Geçin</span>',
    contactInfoTitle: 'İletişim Bilgileri',
    contactInfoDesc: 'Bizimle iletişime geçmek mi istiyorsunuz? <br /> Bu bilgileri takip edin. <br /> Görüşürüz!',
    form: {
      name: 'Adınız',
      namePlaceholder: 'Adınızı girin',
      email: 'E-posta Adresiniz',
      emailPlaceholder: 'E-posta adresinizi girin',
      topic: 'Konu',
      topicPlaceholder: 'Konuyu girin',
      message: 'Mesaj',
      messagePlaceholder: 'Mesajınızı yazın',
      send: 'Gönder',
    },
    messageSent: 'Mesaj gönderildi!',
    sentError: 'Mesaj gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin',
  },
};
