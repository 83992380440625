// локализации будут тут, в формате js, чтобы линтер работал. Достали двойные кавычки
export default {
  menu: {
    login: 'Login',
    signup: 'Sign Up',
    tasks: 'Our Tasks',
    products: 'Products',
    documents: 'Documents',
    contact: 'Contact Us',
  },
  main: {
    title: 'Next-Level Payment <br /> Solutions <span>in Crypto</span>',
    desc: 'Helping you buy, sell, transform, and multiply <br /> <span>digital assets worldwide</span>',
    connect: 'Join Now',
  },
  video: {
    title: 'Delivering Money. Even in the Desert',
  },
  tasks: {
    title: 'What challenges does <br /> <span>Tethex address?</span>',
    task1: 'Cross-border transfers of digital assets',
    task2: 'Payment for goods and services with digital assets',
    task3: 'Buying and selling digital assets<br /><br /> ',
    task4: 'Multiplying <br /> digital assets<br /><br />',
    task5: 'Conversion of <br /> digital assets<br /><br />',
  },
  banks: {
    title: 'Tethex - the world\'s first company to combine <br /> the convenience of traditional finance with the benefits <br /> <span>of digital assets</span>',
    block1: 'Top up Tethex virtual cards using decentralized finance',
    block2: 'Pay for your favorite goods, services, and utilities with Tethex cards',
    smartphone: 'And all this is available <br /> <span>on your smartphone!</span>',
  },
  products: {
    title: 'Products <br /> <span>of the Tethex Platform</span>',
    cardsTitle: 'Cards',
    cardsDesc: 'Virtual cards available for payments worldwide',
    globalFinanceTitle: 'Global Finance',
    globalFinanceDesc: 'Cross-border transfers of digital assets',
    investTitle: 'Invest',
    investDesc: 'Investment program for asset multiplication',
    academyTitle: 'Academy',
    academyDesc: 'Educational service enhancing competencies in digital assets',
    partnerTitle: 'Partner',
    partnerDesc: 'Reward system for company partners<br />&nbsp;',
  },
  productBlocks: {
    crossBorderTitle: 'Cross-Border <br /> <span>Transfers</span>',
    crossBorderSlogan: '<b>Tethex Global</b> - reliably and anonymously.',
    crossBorderDesc: 'The platform ensures advantageous conversion and transfers of decentralized finances worldwide.',
    crossBorderAdvantage1: '<span>High</span> <br /> reliability',
    crossBorderAdvantage2: '<span>No</span> <br /> borders or limitations',
    crossBorderAdvantage3: '<span>Complete</span> <br /> confidentiality',
    virtualCardsTitle: 'Virtual <br /> <span>Cards</span>',
    virtualCardsSlogan: '<b>Tethex Cards</b> - profitably and conveniently.',
    virtualCardsDesc: 'Crypto cards and payment solutions for you and your business. Ideal for paying for goods and services worldwide.',
    virtualCardsAdvantage1: '<span>Top-up</span> <br /> via USDT',
    virtualCardsAdvantage2: '<span>Compatible</span> <br /> with Apple Pay and Google Pay',
    virtualCardsAdvantage3: '<span>Cashback</span> <br /> and interest on balance',
    academyTitle: 'Crypto-Finance <br /> <span>Academy</span>',
    academySlogan: '<b>Tethex Academy</b> - preparing professionals.',
    academyDescription: 'Providing the most in-demand skills and best tools for professional work.',
    academyAdvantage1: '<span>Employment</span> <br /> in any country',
    academyAdvantage2: '<span>Practicing</span> <br /> lecturers',
    academyAdvantage3: '<span>High</span> <br /> agency income',
    investTitle: 'High-Yield <br /> <span>Investments</span>',
    investSlogan: '<b>Tethex Invest</b> - stable income in USDT.',
    investDesc: 'Investments in scaling a successful business. Stable income through receiving part of the profit from the company\'s activities.',
    investAdvantage1: '<span>Daily</span> <br /> payouts',
    investAdvantage2: '<span>Accessible</span> <br /> investments',
    investAdvantage3: '<span>Official</span> <br /> contract',
  },
  events: {
    title: 'Our <br /> <span>Events</span>',
    kazan: {
      date: 'January 27-29, 2024',
      title: 'First Conference <br /> <span>Kazan 2024</span>',
      desc: 'Conference dedicated <br /> to financial logistics',
      place: '<span>NEO Kazan Palace</span>&nbsp; by TASIGO <div>3B Kalinina St., Kazan, Russia</div>',
    },
    dubai: {
      date: 'Coming Soon...',
      title: 'Finance Logistic <br /> <span>Dubai 2024</span>',
      desc: '<b>Tethex</b> - a new stage in the development of the financial system',
      place: '<span>Coming Soon</span>',
    },
  },
  geography: {
    title: 'Our <br /> <span>Locations</span>',
    dubai: {
      type: 'Head Office',
      city: 'Dubai',
      country: 'UAE',
      address: 'Business Bay Dubai',
    },
    russia: {
      type: 'Representation',
      city: 'Kazan',
      country: 'Russia',
      address: 'Tethex Finance LLC, <br /> Kazan, Russia <br /> Sary Sadykovoy St. 30 <br /> Bahadir Shopping Mall, 3rd Floor',
    },
    china: {
      soon: 'Coming Soon',
    },
  },
  certificates: {
    title: 'Our <span>Certificates</span>',
    certificate1: 'Business License <br /> in UAE',
    certificate2: 'Business registration <br /> in Dubai',
    certificate3: 'Management Quality <br /> Standard Certificate',
  },
  contacts: {
    title: 'Get in Touch <br /> <span>with Us</span>',
    contactInfoTitle: 'Contact Information',
    contactInfoDesc: 'Want to reach out to us? <br /> Follow these details. <br /> See you soon!',
    form: {
      name: 'Your Name',
      namePlaceholder: 'Enter your name',
      email: 'Your E-Mail',
      emailPlaceholder: 'Enter E-Mail',
      topic: 'Topic',
      topicPlaceholder: 'Enter topic',
      message: 'Message',
      messagePlaceholder: 'Write your message',
      send: 'Send',
    },
    messageSent: 'Message sent!',
    sentError: 'An error occurred while sending the message. Please try again later',
  },
};
