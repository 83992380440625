import { FormikProvider, useFormik } from 'formik';
import FormikTextInput from '../../components/Formik/FormikTextInput';
import FormikTextarea from '../../components/Formik/FormikTextarea';
import SVGShield from '../../assets/svg/SVGShield';
import SVGEnvelope from '../../assets/svg/SVGEnvelope';
import SVGPlacemarkSm from '../../assets/svg/SVGPlacemarkSm';
import SVGTelegramSm from '../../assets/svg/SVGTelegramSm';
import { sendMail } from '../../api';
import { contactValidation } from '../../features/validations/contactValidation';
import { errorToast, successToast } from '../../features/helpers';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import SafeHTML from '../../components/SafeHTML';

export default function Contact() {
  const { t } = useCustomTranslation();
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      topic: '',
      content: '',
    },
    validationSchema: contactValidation,
    onSubmit: async (data) => {
      try {
        await sendMail(data);
        successToast({ text: t('contacts.messageSent') });
        resetForm();
      } catch (e) {
        errorToast({ text: t('contacts.sentError') });
      }
    },
  });
  const { submitForm, resetForm } = formik;
  return (
    <div className="contact" id="contact">
      <div className="main-container">
        <div className="default-title --lg">
          <SafeHTML html={t('contacts.title')} />
        </div>
        <div className="contact-form">
          <div className="contact-form__info">
            <div className="contact-form__info--title">
              {t('contacts.contactInfoTitle')}
            </div>
            <div className="contact-form__info--text">
              <SafeHTML html={t('contacts.contactInfoDesc')} />
            </div>
            <ul className="contact-form__info--list">
              <li>
                <SVGTelegramSm />
                <div>
                  @tethex
                </div>
              </li>
              <li>
                <SVGEnvelope />
                <div>
                  info@tethex.io
                </div>
              </li>
              <li>
                <SVGPlacemarkSm />
                <div>
                  Business bay Dubai
                </div>
              </li>
            </ul>
            <SVGShield />
          </div>
          <div className="contact-form__inputs">
            <div className="contact-form__inputs--grid">
              <FormikProvider value={formik}>
                <FormikTextInput containerClass="--name" name="name" label={t('contacts.form.name')} placeholder={t('contacts.form.namePlaceholder')} />
                <FormikTextInput containerClass="--email" name="email" label={t('contacts.form.email')} placeholder={t('contacts.form.emailPlaceholder')} />
                <FormikTextInput containerClass="--subject" name="topic" label={t('contacts.form.topic')} placeholder={t('contacts.form.topicPlaceholder')} />
                <FormikTextarea className="--message" name="content" label={t('contacts.form.message')} placeholder={t('contacts.form.messagePlaceholder')} limit={150} />
              </FormikProvider>
            </div>
            <button className="default-button --lg" onClick={submitForm}>
              {t('contacts.form.send')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
