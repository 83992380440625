export default function SVGMenu() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.6082 0H7.66133C9.10078 0 10.2695 1.16875 10.2695 2.6082V7.67422C10.2695 9.11367 9.10078 10.2824 7.66133 10.2824H2.6082C1.16875 10.2824 0 9.11367 0 7.67422V2.6082C0 1.16875 1.16875 0 2.6082 0Z" fill="white" />
      <path opacity="0.7" d="M14.3426 0H19.3957C20.8352 0 22.0039 1.16875 22.0039 2.6082V7.67422C22.0039 9.11367 20.8352 10.2824 19.3957 10.2824H14.3426C12.9031 10.2824 11.7344 9.11367 11.7344 7.67422V2.6082C11.7344 1.16875 12.9031 0 14.3426 0Z" fill="white" />
      <path d="M2.6082 11.7176H7.66133C9.10078 11.7176 10.2695 12.8863 10.2695 14.3258V19.3918C10.2695 20.8313 9.10078 22 7.66133 22H2.6082C1.16875 22 0 20.8313 0 19.3918V14.3258C0 12.8863 1.16875 11.7176 2.6082 11.7176Z" fill="white" />
      <path d="M14.3426 11.7174H19.3957C20.8352 11.7174 22.0039 12.8862 22.0039 14.3256V19.3916C22.0039 20.8311 20.8352 21.9998 19.3957 21.9998H14.3426C12.9031 21.9998 11.7344 20.8311 11.7344 19.3916V14.3256C11.7344 12.8862 12.9031 11.7174 14.3426 11.7174Z" fill="white" />
    </svg>
  );
}
