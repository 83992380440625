/* eslint-disable prefer-destructuring */
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LOCALES_LOCAL_STORAGE_KEY } from './constants';
import ru from './locales/ru/translation';
import en from './locales/en/translation';
import tu from './locales/tu/translation';

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  debug: false,
  load: 'languageOnly',
  resources: {
    ru: {
      translation: ru,
    },
    en: {
      translation: en,
    },
    tu: {
      translation: tu,
    },
  },
  detection: {
    order: ['localStorage', 'navigator'],
    lookupLocalStorage: LOCALES_LOCAL_STORAGE_KEY,
    caches: ['localStorage', 'cookie'],
  },
  interpolation: {
    escapeValue: false,
  },
});

i18n.on('languageChanged', (lng) => {
  let isoLanguage = lng;

  if (lng.includes('_')) isoLanguage = lng.split('_')[0];
  if (lng.includes('-')) isoLanguage = lng.split('-')[0];
  if (lng.length === 3) isoLanguage = lng.slice(0, -1);

  // Применить изменение языка
  if (isoLanguage !== lng) {
    i18n.changeLanguage(isoLanguage);
  }
});

export default i18n;
