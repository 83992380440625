import { useField } from 'formik';
import { useState } from 'react';
import { cx, FUNCTION_RETURN_NULL, getId } from '../../../features/helpers';
import SVGPasswordShow from '../../../assets/svg/SVGPasswordShow';
import useValueTransformers from '../../../features/hooks/useValueTransformers';

export default function FormikTextInput({
  label = '',
  name = '',
  placeholder = '', id = '', type = 'text',
  containerClass = '',
  inputClass = '',
  description = '',
  onChange = FUNCTION_RETURN_NULL,
  onFocus = FUNCTION_RETURN_NULL,
  onBlur = FUNCTION_RETURN_NULL,
  disabled,
  passwordToggle = true,
  transformer = FUNCTION_RETURN_NULL,
  tabIndex = 0,
  currency = '',
}) {
  // eslint-disable-next-line no-unused-vars
  const [field, { touched, error, value }, { setValue, setTouched }] = useField(name);
  const [generatedId] = useState(id || getId());
  const isError = error && touched;
  const [showPassword, togglePassword] = useState(false);
  useValueTransformers(value, transformer, setValue);
  return (
    <div className={cx('input--holder', containerClass)}>
      {label && (
        <label htmlFor={generatedId}>{label}</label>
      )}
      <div className="input--relative">
        <input
          type={showPassword ? 'text' : type}
          id={generatedId}
          disabled={disabled}
          placeholder={placeholder}
          className={
            cx(
              'default-input',
              (error && touched) && '--error',
              inputClass,
              ((type === 'password ' && passwordToggle) && '--toggleable-password'),
              (currency && '--has-currency'),
            )
          }
          onChange={onChange}
          onKeyUp={onChange}
          onKeyDown={onChange}
          tabIndex={tabIndex}
          onFocus={onFocus}
          onBlurCapture={onBlur}
          {...field}
        />
        {currency && (
          <div className="input--currency">
            { currency }
          </div>
        )}
      </div>
      {isError && (
        <span className="input--error">
          {error}
        </span>
      )}
      {description && (
        <div className="input--description">
          { description }
        </div>
      )}
      {type === 'password' && passwordToggle ? (
        <button className={cx('input--toggle', showPassword && '--password-visible')} onClick={() => togglePassword(!showPassword)}>
          <SVGPasswordShow />
        </button>
      ) : null}
    </div>
  );
}
