import { useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import useCustomTranslation from '../../features/hooks/useCustomTranslation';
import { LOCALES } from '../../features/constants';
import SVGArrowDown from '../../assets/svg/SVGArrowDown';
import { useOutsideClick } from '../../features/hooks/useOutsideClick';
import SVGCloseModal from '../../assets/svg/SVGCloseModal';

export default function LanguageSelector() {
  const [isShow, setIsShow] = useState(false);
  const { lng, changeLanguage } = useCustomTranslation();
  const listRef = useRef();
  useOutsideClick(listRef, () => {
    if (isShow) {
      setIsShow(false);
    }
  });
  const selectHandler = (code) => {
    changeLanguage(code);
    setIsShow(false);
  };
  return (
    <div className="language-selector">
      <div className="language-selector__current" onClick={() => setIsShow(!isShow)}>
        { lng }
        <SVGArrowDown fill="#CFB37B" />
      </div>
      {
        createPortal(
          <AnimatePresence>
            {isShow && (
              <motion.div
                className="language-selector__list"
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
              >
                <div className="language-selector__list--inset" ref={listRef}>
                  <button className="language-selector__close" onClick={() => setIsShow(false)}>
                    <SVGCloseModal />
                  </button>
                  <div className="language-selector__list--inset__holder">
                    <div className="language-selector__list--title">
                      Select Language:
                    </div>
                    <div className="language-selector__buttons">
                      {LOCALES.map(({ code, name, key }) => (
                        <button className="language-selector__buttons--item" onClick={() => selectHandler(code)} key={key}>
                          { name }
                          <span>
                            <i>
                              →
                            </i>
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>,
          document.getElementById('root'),
        )
      }
    </div>
  );
}
