import { useTranslation } from 'react-i18next';

export default function useCustomTranslation() {
  const { t, i18n, ...rest } = useTranslation();
  const changeLanguage = async (language) => {
    await i18n.changeLanguage(language);
  };
  return {
    t, i18n, changeLanguage, lng: i18n.language, ...rest,
  };
}
