// локализации будут тут, в формате js, чтобы линтер работал. Достали двойные кавычки
export default {
  menu: {
    login: 'Войти',
    signup: 'Регистрация',
    tasks: 'Наши задачи',
    products: 'Продукты',
    documents: 'Документы',
    contact: 'Обратная связь',
  },
  main: {
    title: 'Платежные решения <br /> <span>нового уровня</span>',
    desc: 'Помогаем покупать, продавать, трансформировать и преумножать <br /> <span>цифровые активы во всем мире</span>',
    connect: 'Присоединиться',
  },
  video: {
    title: 'Доставим деньги. Даже в пустыню',
  },
  tasks: {
    title: 'Какие задачи решает <br /> <span>компания Tethex?</span>',
    task1: 'Трансграничные переводы цифровых активов',
    task2: 'Оплата товаров и услуг цифровыми активами',
    task3: 'Покупка и продажа цифровых активов<br />&nbsp;',
    task4: 'Преумножение <br /> цифровых активов<br />&nbsp;',
    task5: 'Конвертация <br /> цифровых активов <br />&nbsp;',
  },
  banks: {
    title: 'Tethex - первая в мире компания, соединившая <br /> удобство традиционных финансов и преимущества <br /> <span>цифровых активов</span>',
    block1: 'Пополняйте виртуальные карты Tethex при помощи децентрализованных финансов',
    block2: 'Оплачивайте картами Tethex привычные вам товары, услуги и сервисы',
    smartphone: 'И все это доступно <br /> <span>в вашем смартфоне!</span>',
  },
  products: {
    title: 'Продукты <br /> <span>платформы Tethex</span>',
    cardsTitle: 'Cards',
    cardsDesc: 'Виртуальные карты, доступные для платежей во всем мире',
    globalFinanceTitle: 'Global Finance',
    globalFinanceDesc: 'Трансграничные переводы цифровых активов',
    investTitle: 'Invest',
    investDesc: 'Инвестиционная программа для преумножения капитала',
    academyTitle: 'Academy',
    academyDesc: 'Образовательный сервис, повышающий компетенции в сфере цифровых активов',
    partnerTitle: 'Partner',
    partnerDesc: 'Система вознаграждений для партнеров компании',
  },
  productBlocks: {
    crossBorderTitle: 'Трансграничные <br /> <span>переводы</span>',
    crossBorderSlogan: '<b>Tethex Global</b> - надежно и анонимно.',
    crossBorderDesc: 'Платформа обеспечивает выгодную конвертацию и переводы децентрализованных финансов по всему миру',
    crossBorderAdvantage1: '<span>Высокая</span> <br /> надежность',
    crossBorderAdvantage2: '<span>Без границ</span> <br /> и ограничений',
    crossBorderAdvantage3: '<span>Полная</span> <br /> конфиденциальность',
    virtualCardsTitle: 'Виртуальные <br /> <span>карты</span>',
    virtualCardsSlogan: '<b>Tethex Cards</b> - выгодно и удобно.',
    virtualCardsDesc: 'Крипто-карты и платежные решения для вас и вашего бизнеса. Идеальны для оплаты товаров и услуг во всем мире.',
    virtualCardsAdvantage1: '<span>Пополнение</span> <br /> через USDT',
    virtualCardsAdvantage2: '<span>Совместимы</span> <br /> с Apple Pay и Google Pay',
    virtualCardsAdvantage3: '<span>Кэшбэк</span> <br /> и процент на остаток',
    academyTitle: 'Академия <br /> <span>крипто-финансов</span>',
    academySlogan: '<b>Tethex Academy</b> - готовим профессионалов.',
    academyDescription: 'Передаем самые востребованные навыки и лучшие инструменты для профессиональной работы',
    academyAdvantage1: '<span>Трудоустройство</span> <br /> в любой стране',
    academyAdvantage2: '<span>Практики</span> <br /> преподаватели',
    academyAdvantage3: '<span>Высокий</span> <br /> агентский доход',
    investTitle: 'Высокодоходные <br /> <span>инвестиции</span>',
    investSlogan: '<b>Tethex Invest</b> - стабильный доход в USDT.',
    investDesc: 'Инвестиции в масштабирование успешного бизнеса. Стабильный доход за счет получения части прибыли от деятельности компании.',
    investAdvantage1: '<span>Ежедневные</span> <br /> выплаты',
    investAdvantage2: '<span>Доступные</span> <br /> инвестиции',
    investAdvantage3: '<span>Официальный</span> <br /> договор',
  },
  events: {
    title: 'Наши <br /> <span>Мероприятия</span>',
    kazan: {
      date: '27-29 января 2024',
      title: 'Первая конференция <br /> <span>Казань 2024</span>',
      desc: 'Конференция, посвященная финансовой логистике',
      place: '<span>NEO Kazan Palace</span> &nbsp; by TASIGO <div>г. Казань, ул. Калинина, д. 3В</div>',
    },
    dubai: {
      date: 'Скоро анонс...',
      title: 'Finance Logistic <br /> <span>Dubai 2024</span>',
      desc: '<b>Tethex</b> - новый этап развития финансовой системы',
      place: '<span>Скоро анонс</span>',
    },
  },
  geography: {
    title: 'Наша <br /> <span>география</span>',
    dubai: {
      type: 'Головной офис',
      city: 'Дубай',
      country: 'ОАЭ',
      address: 'Business bay Dubai',
    },
    russia: {
      type: 'Представительство',
      city: 'Казань',
      country: 'Россия',
      address: 'ООО «Тезекс Финанс», <br /> Казань, Россия <br /> Ул.Сары Садыковой 30 <br /> ТЦ «Бахадир» 3 этаж',
    },
    china: {
      soon: 'Скоро <br /> открытие',
    },
  },
  certificates: {
    title: 'Наши <span>сертификаты</span>',
    certificate1: 'Лицензия на ведение <br /> бизнеса в ОАЭ',
    certificate2: 'Регистрация бизнеса <br /> в Дубае',
    certificate3: 'Сертификат стандарта <br /> качества менеджмента',
  },
  contacts: {
    title: 'Свяжитесь <br /> <span>с нами</span>',
    contactInfoTitle: 'Контактная информация',
    contactInfoDesc: 'Хочешь связаться с нами? <br /> Следуй по этим реквизитам. <br /> Увидимся!',
    form: {
      name: 'Ваше имя',
      namePlaceholder: 'Введите имя',
      email: 'Ваш E-Mail',
      emailPlaceholder: 'Введите E-Mail',
      topic: 'Тема',
      topicPlaceholder: 'Введите тему',
      message: 'Сообщение',
      messagePlaceholder: 'Напишите свое сообщение',
      send: 'Отправить',
    },
    messageSent: 'Письмо отправлено!',
    sentError: 'Произошла ошибка при отправке письма. Пожалуйста, попробуйте позже',
  },
};
